import { Environment } from './environment.type';

export const environment: Environment = {
  name: 'production',
  production: false,
  api: {
    host: 'api.app-staging.c-rayon.com',
    port: 443,
    ssl: true,
  }
};
